import React from "react";
import { FocusTrap, MantineProvider, Text } from "@mantine/core";
import Home from "./components/home/Home";

function App() {
  return (
    <MantineProvider
      withGlobalStyles
      withNormalizeCSS
      theme={{ colorScheme: "dark", focusRing: "never" }}
    >
      <Home/>
    </MantineProvider>
  );
}

export default App;
