import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { Avatar, Card, Container, Group, Progress, Stack, Text } from "@mantine/core";

interface SkillsCardProps {
  name: string;
  icon: ReactJSXElement;
  color: string;
  progress: number;
}

export default function SkillsCard({ name, icon, color, progress }: SkillsCardProps) {
  return (
    <Card m="xs">
      <Group>
        <Avatar variant="outline" color={color} size="md" radius={50}>
          {icon}
        </Avatar>
        <Stack align="flex-start" justify="flex-start" spacing="xs">
          <Text weight={500}>{name}</Text>
        </Stack>
      </Group>
      <Progress mt="sm" color={color} value={progress} animate/>
    </Card>
  );
}
