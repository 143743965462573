import {
  ActionIcon,
  Avatar,
  Center,
  Container,
  createStyles,
  Divider,
  Group,
  Paper,
  Stack,
  Text,
} from "@mantine/core";
import Skills from "../skills/Skills";
import SocialTab from "../SocialTab";
import "./Home.css";

const useStyles = createStyles((theme) => ({
  about: {
    marginTop: "16vh",
    padding: theme.spacing.md,
    width: "50vw",
    height: "28vh",

    "@media (max-width: 1200px)": {
      backgroundColor: "transparent",
      marginTop: "8.5vh",
      minWidth: "85vw",
    },
  },

  skills: {
    marginTop: "2vh",
    padding: theme.spacing.md,
    width: "50vw",
    height: "28vh",

    "@media (max-width: 1200px)": {
      backgroundColor: "transparent",
      minWidth: "90vw",
      margin: "10vh"
    },
  }
}));

export default function Home() {
  const { classes } = useStyles();

  return (
    <Container className="wrapper" fluid>
      <Center>
        <Paper className={classes.about}>
          <Center>
            <Stack spacing="sm">
              <Center>
                <Avatar
                  size="xl"
                  radius={50}
                  src="https://cdn.discordapp.com/avatars/703034602306142281/0ddd6c7dcac5b2def3c706e9c77cee1e"
                  variant="filled"
                  color="blue"
                />
              </Center>
              <Text size={32} weight="bold" color="gray.1">
                Igor Staszkiewicz
              </Text>
              <Text size="md" weight={500} align="center" color="dark.1">
                Software Developer
              </Text>
              <Divider />
              <Group position="center">
                <SocialTab
                  element={
                    <i
                      className="devicon-github-original"
                      style={{ fontSize: "28px" }}
                    ></i>
                  }
                  label="Github"
                  href="https://github.com/metropanties"
                />
                <SocialTab
                  element={
                    <img
                      src={process.env.PUBLIC_URL + "/discord.png"}
                      alt="discord"
                      width={28}
                      height={28}
                    />
                  }
                  label="metropants#4680"
                />
              </Group>
            </Stack>
          </Center>
        </Paper>
      </Center>
      <Center>
        <Paper className={classes.skills}>
          <Skills />
        </Paper>
      </Center>
    </Container>
  );
}
