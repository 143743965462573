import { Container, SimpleGrid } from "@mantine/core";
import SkillsCard from "./SkillsCard";

export default function Skills() {
  return (
    <Container>
      <SimpleGrid
        cols={2}
        spacing="xs"
        breakpoints={[
          { maxWidth: 980, cols: 2, spacing: 2 },
          { maxWidth: 755, cols: 2, spacing: 2 },
          { maxWidth: 600, cols: 1, spacing: 2 },
        ]}
      >
        <SkillsCard icon={<i className="devicon-java-plain" />} color="red" name="Java" progress={80}/>
        <SkillsCard icon={<i className="devicon-spring-plain" />} color="green" name="Spring Boot" progress={50}/>
        <SkillsCard icon={<i className="devicon-javascript-plain" />} color="yellow" name="Javascript" progress={45}/>
        <SkillsCard icon={<i className="devicon-typescript-plain" />} color="blue" name="Typescript" progress={45}/>
        <SkillsCard icon={<i className="devicon-docker-plain" />} color="blue" name="Docker" progress={15}/>
        <SkillsCard icon={<i className="devicon-postgresql-plain" />} color="blue" name="PostgresSQL" progress={60}/>
        <SkillsCard icon={<i className="devicon-mongodb-plain" />} color="green" name="MongoDB" progress={60}/>
        <SkillsCard icon={<i className="devicon-nextjs-plain" />} color="gray.5" name="Next.js" progress={25}/>
      </SimpleGrid>
    </Container>
  );
}
