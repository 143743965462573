import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { ActionIcon, Tooltip } from "@mantine/core";

interface SocialTabProps {
  element: ReactJSXElement;
  label: string;
  href?: string;
}

export default function SocialTab({ element, label, href }: SocialTabProps) {
  return (
    <>
      <Tooltip label={label} position="bottom" offset={10}>
        <ActionIcon variant="transparent" component="a" href={href!}>
            {element}
        </ActionIcon>
      </Tooltip>
    </>
  );
}
